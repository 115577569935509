<template>
  <div>
    <b-card class="col-md-6">
      <h1 class="light-blue font-weight-bolder">Aequitex</h1>
      <p class="light-blue">{{$t('admin_dashboard')}}</p>
      <b-row m-0 p-0>
        <b-col cols="7" class="card-text text-muted font-small-2">
          <h5>
            <b-badge variant="success">
              {{ countedUsers }}
            </b-badge>
            {{$t('registered_users')}}
          </h5>
        </b-col>
        <b-col cols="5">

        </b-col>
      </b-row>
    </b-card>
    <CompaniesList listStatus="pending" />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import CompaniesList from '@/components/Company/CompaniesList.vue'
export default {
  components: {

    CompaniesList,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      countedUsers: 0,
    };
  },
  created() {
    this.getUsers();
    // console.log(this.$store.getters['auth/user'].token);
  },
  methods: {
    getUsers() {
      this.$http.post("/user/list").then((res) => {
        this.countedUsers = res.data.value.total;
      });
    },
  },
};
</script>

<style scoped>
.card-text {
  display: inline-flex;
}
</style>
